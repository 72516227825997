import axios from "axios";
import { getToken, removeToken } from "../../utils/helpers";

export const BUSINESS_API_URL = process.env.REACT_APP_BUSINESS_API_URL;
export const getBusinessApiUrl = () => `${BUSINESS_API_URL}/api/v1`;

export const businessApiClient = axios.create({
  baseURL: getBusinessApiUrl(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": "ka-GE",
    "Application-Type": "Web",
    "FCM-Token": "DeviceId",
  },
});

businessApiClient.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log("request error", error);
    return Promise.reject(error);
  },
);

businessApiClient.interceptors.response.use(
  (resp) => resp,
  (err) => {
    console.log(err);
    if (err.response.status.toString() === "401") {
      removeToken();
    }
    //remove token from localstorage
    //redirect to login
  },
);
