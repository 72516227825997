import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { CompanyData } from "../../../../services/business/company";

type FilteredCompanies = CompanyData[] | undefined;

type CompaniesFilters = {
  filteredCompanies: FilteredCompanies;
};

export const useCompaniesFilters = (
  companies: CompanyData[] | undefined,
): CompaniesFilters => {
  const [filteredCompanies, setFilteredCompanies] =
    useState<FilteredCompanies>(companies);

  useEffect(() => {
    if (companies) setFilteredCompanies(companies);
  }, [companies]);

  return {
    filteredCompanies,
  };
};
