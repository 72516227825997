import { GeneralResponseType } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

export type OtpVerifyCodeReq = {
  phoneNumber: string;
  hash: string;
  code: string;
};

type TokenData = {
  accessToken: string;
  refreshToken: string;
};

type VerifyOtpCodeResp = GeneralResponseType<TokenData>;

export const verifyOtpCode = (
  data: OtpVerifyCodeReq,
): Promise<VerifyOtpCodeResp> =>
  apiClient.post("/auth/gosmsLogin", data).then((resp) => resp.data);

export const useVerifyOtpCode = (
  onSuccess?: (data: VerifyOtpCodeResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(verifyOtpCode, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
