import { GeneralResponseType, IOption, Status } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useQuery } from "react-query";

type GetSkillsResp = GeneralResponseType<IOption[]>;

export const getSkills = (): Promise<GetSkillsResp> =>
  apiClient.get("/catalog/skills").then((resp) => resp.data);

export type UseGetSkillsResp = {
  isLoading: boolean;
  data?: IOption[];
  status?: Status;
  refetch: any;
  isRefetching: boolean;
};

export const useGetSkills = (): UseGetSkillsResp => {
  const { data, isLoading, refetch, isRefetching } = useQuery(
    ["catalog-skills"],
    getSkills,
  );
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};
