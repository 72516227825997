import React, { useEffect, useState } from "react";
import "./DoersList.scss";
import { Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebouncedCallback } from "use-debounce";
import { DoerItem, MultipleSelect } from "../../../../components";
import { IOption } from "../../../../services/generalTypes";
import { Doer } from "../../../../services/shared-types";
import { TaskDoer } from "../../../../services/retail/task/useGetTaskDetails";

interface IDoersList {
  doers: Doer[];
  skills?: IOption[];
  onDoerClick?: (id: string, doer: Doer | TaskDoer) => void;
  setSelectedSkills: any;
}

export const DoersList: React.FC<IDoersList> = ({ doers, onDoerClick }) => {
  return (
    <div className={"doers-list-content-wrapper"}>
      <div className={"doers-list-items-wrapper"}>
        {doers.map((doer) => {
          return (
            <Row key={doer.id}>
              <DoerItem doer={doer} onDoerClick={onDoerClick} />
            </Row>
          );
        })}
      </div>
    </div>
  );
};
