import React, { useEffect, useState } from "react";
import "./Doers.scss";
import { useNavigate } from "react-router";
import { DoersList } from "./DoersList/DoersList";
import { Button, Pagination, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { MultipleSelect, SearchInput } from "../../../components";
import { useDebouncedCallback } from "use-debounce";
import { AddDoerModal } from "./ActionModals/AddDoerModal/AddDoerModal";
import { CustomSelect } from "../../../components/CustomSelect/CustomSelect";
import {
  useGetCities,
  UseGetCitiesResp,
} from "../../../services/retail/catalog";
import {
  useGetSkills,
  UseGetSkillsResp,
} from "../../../services/retail/catalog";
import {
  UseGetBusinessCitiesResp,
  UseGetBusinessSkillsResp,
} from "../../../services/business/catalog";
import { UseQueryOptions } from "react-query";
import {
  DoersParams,
  useGetDoers,
  UseGetDoersResp,
} from "../../../services/retail/doer/useGetDoers";
import { UseGetBusinessDoersResp } from "../../../services/business/doer/useGetBusinessDoers";
import { Doer } from "../../../services/shared-types";
import { TaskDoer } from "../../../services/retail/task/useGetTaskDetails";

interface IDoers {
  pageSize?: number;
  onDoerClick?: (id: string, doer: Doer | TaskDoer) => void;
  withAddDoer?: boolean;
  defaultSelectedSkills?: Array<number | string>;
  getCities?: () => UseGetCitiesResp | UseGetBusinessCitiesResp;
  getSkills?: () => UseGetSkillsResp | UseGetBusinessSkillsResp;
  getDoers?: (
    params?: DoersParams,
    options?: UseQueryOptions<any>,
  ) => UseGetDoersResp | UseGetBusinessDoersResp;
  skillsParamName?: string;
}

export const Doers: React.FC<IDoers> = ({
  pageSize,
  onDoerClick,
  withAddDoer = false,
  defaultSelectedSkills,
  getCities = useGetCities,
  getSkills = useGetSkills,
  getDoers = useGetDoers,
  skillsParamName = "skillIds",
}) => {
  const navigate = useNavigate();
  const [selectedSkills, setSelectedSkills] = useState<Array<string | number>>(
    defaultSelectedSkills || [],
  );
  const [selectedCity, setSelectedCity] = useState<number>(1);
  const [searchText, setSearchText] = useState<string | undefined>();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: pageSize || 6,
  });
  const { isLoading: citiesLoading, data: cities } = getCities();
  const { isLoading: skillsLoading, data: skills } = getSkills();
  const { doers, isLoading, refetch } = getDoers({
    pageNumber: pagination.currentPage,
    pageSize: pagination.pageSize,
    searchText,
    [skillsParamName]: selectedSkills,
    cityId: selectedCity,
  });
  const [filteredDoersData, setFilteredDoersData] = useState<Doer[]>();
  const [addDoerModalOpen, setAddDoerModalOpen] = useState(false);

  useEffect(() => {
    if (doers) {
      setFilteredDoersData(doers?.data);
    }
  }, [doers]);

  const getSkillIds = () => {
    if (skills) {
      return skills.map((item) => item.id);
    }
    return [];
  };

  useEffect(() => {
    if (defaultSelectedSkills) {
      setSelectedSkills(defaultSelectedSkills);
    } else {
      setSelectedSkills(getSkillIds);
    }
  }, [skills]);

  const onSearch = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (pagination.currentPage !== 1) {
        resetPagination();
      }
      setSearchText(e.target.value);
    },
    200,
  );

  const resetPagination = () => {
    setPagination({
      currentPage: 1,
      pageSize: pageSize || 6,
    });
  };

  return (
    <div className={"doers-wrapper"}>
      <div className={"doers-list-filters-wrapper"}>
        <SearchInput onChange={onSearch} />
        <MultipleSelect
          placeholder="კატეგორია"
          onChange={(selected: Array<number>) => {
            if (selected?.length) {
              setSelectedSkills(selected);
            } else {
              setSelectedSkills(getSkillIds);
            }
            resetPagination();
          }}
          options={skills}
          loading={skillsLoading}
          defaultValue={defaultSelectedSkills}
        />
        <CustomSelect
          value={selectedCity}
          defaultValue={1}
          options={cities}
          placeholder={"ქალაქი"}
          loading={citiesLoading}
          onChange={(value: number) => {
            setSelectedCity(value);
            resetPagination();
          }}
        />
        {withAddDoer && (
          <>
            <Button
              className={"add-new-doer-button"}
              onClick={() => setAddDoerModalOpen(true)}
            >
              <PlusOutlined />
            </Button>
            <AddDoerModal
              modalOpen={addDoerModalOpen}
              setModalOpen={setAddDoerModalOpen}
              refetchDoers={refetch}
            />
          </>
        )}
      </div>
      <div className={`doers-list-wrapper ${isLoading ? "loading" : ""}`}>
        {!isLoading ? (
          filteredDoersData ? (
            <DoersList
              doers={filteredDoersData}
              onDoerClick={(id: string, doer: Doer | TaskDoer) => {
                if (onDoerClick) {
                  onDoerClick(id, doer);
                } else {
                  navigate(`/doers/${id}`);
                }
              }}
              skills={skills}
              setSelectedSkills={setSelectedSkills}
            />
          ) : (
            <></>
          )
        ) : (
          <Spin />
        )}
      </div>
      <Pagination
        className={"doers-list-pagination"}
        current={pagination.currentPage}
        defaultCurrent={1}
        total={doers?.totalItemCount || 0}
        pageSize={pagination.pageSize}
        pageSizeOptions={[6, 15, 20, 50]}
        onChange={(page) => {
          setPagination((prev) => ({ ...prev, currentPage: page }));
        }}
        onShowSizeChange={(_, size) => {
          setPagination((prev) => ({ ...prev, pageSize: size }));
        }}
      />
    </div>
  );
};
