import React from "react";
import { Button, Col, Form, message, Row } from "antd";
import { formValidation } from "../../../../../utils/formValidation";
import { CustomInput } from "../../../../../components";
import {
  useRegisterWithLink,
  UserId,
} from "../../../../../services/business/auth";

type AddUserStepProps = {
  onAddUserSuccess: (userId: UserId) => void;
};

export const AddUserStep: React.FC<AddUserStepProps> = ({
  onAddUserSuccess,
}) => {
  const form = Form.useFormInstance();
  const { mutate: registerUser, isLoading: registerUserLoading } =
    useRegisterWithLink();

  const onNext = () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldsValue();
        registerUser(values, {
          onSuccess: (resp) => {
            message.success("მომხმარებელი წარმატებით შექიმნა");
            onAddUserSuccess(resp.userId);
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={"add-company-subtitle"}>
        შეიყვანეთ დამატებითი ინფორმაცია მომხმარებლის შესახებ
      </div>
      <Row>
        <Col span={24}>
          <Form.Item name={"firstName"} rules={formValidation.required}>
            <CustomInput placeholder={"სახელი"} />
          </Form.Item>
          <Form.Item name={"lastName"} rules={formValidation.required}>
            <CustomInput placeholder={"გვარი"} />
          </Form.Item>
          <Form.Item name={"email"} rules={formValidation.required}>
            <CustomInput placeholder={"ელ-ფოსტა"} disabled />
          </Form.Item>
          <Form.Item
            name={"phoneNumber"}
            rules={[...formValidation.required, ...formValidation.phoneNumber]}
          >
            <CustomInput placeholder={"ტელეფონის ნომერი"} />
          </Form.Item>
        </Col>
      </Row>
      <div className={"buttons-wrapper"}>
        <Button
          htmlType={"submit"}
          className={"next-button"}
          onClick={onNext}
          loading={registerUserLoading}
        >
          შემდეგი
        </Button>
      </div>
    </>
  );
};
