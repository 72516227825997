import React, { useState } from "react";
import { Task } from "../Task/Task";
import { Button, message } from "antd";
import { RejectTaskModal } from "../ActionModals/RejectTaskModal/RejectTaskModal";
import { ConfirmationModal } from "../ActionModals";
import { TaskDetails } from "../../../../services/retail/task/useGetTaskDetails";
import { useAcceptTaskOffer } from "../../../../services/retail/task/useAcceptTaskOffer";

interface IAssignedTaskWrapper {
  task: TaskDetails;
  refetchTask: any;
}

export const AssignedTaskWrapper: React.FC<IAssignedTaskWrapper> = ({
  task,
  refetchTask,
}) => {
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [acceptOfferModalOpen, setAcceptOfferModalOpen] =
    useState<boolean>(false);

  const { mutate: mutateAcceptOffer, isLoading: acceptOfferLoading } =
    useAcceptTaskOffer();

  const handleAcceptTaskOffer = () => {
    mutateAcceptOffer(
      {
        taskId: task.id,
      },
      {
        onSuccess: () => {
          setAcceptOfferModalOpen(false);
          refetchTask().catch((err: any) => message.error(err));
        },
        onError: (err) => {
          message.error(err);
        },
      },
    );
  };

  return (
    <Task
      task={task}
      actionButtons={
        <>
          <Button
            className={"action-button red"}
            onClick={() => setRejectModalOpen(true)}
          >
            {"დავალების გაუქმება"}
          </Button>
          <RejectTaskModal
            modalOpen={rejectModalOpen}
            setModalOpen={setRejectModalOpen}
            title={"დავალების გაუქმება"}
            confirmationText={"ნამდვილად გსურთ დავალების გაუქმება?"}
            task={task}
          />
          <Button
            className={"action-button green"}
            onClick={() => setAcceptOfferModalOpen(true)}
          >
            {"შემოთავაზების მიღება"}
          </Button>
          <ConfirmationModal
            modalOpen={acceptOfferModalOpen}
            setModalOpen={setAcceptOfferModalOpen}
            title={"შემოთავაზების მიღება"}
            confirmationText={"ნამდვილად გსურთ შემოთავაზების მიღება?"}
            onSubmit={handleAcceptTaskOffer}
            color={"#06D672"}
            isLoading={acceptOfferLoading}
          />
        </>
      }
    />
  );
};
