import { GeneralResponseType, IOption, Status } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useQuery } from "react-query";

type GetCitiesResp = GeneralResponseType<IOption[]>;

export const getCities = (): Promise<GetCitiesResp> =>
  apiClient.get("/catalog/cities").then((resp) => resp.data);

export type UseGetCitiesResp = {
  isLoading: boolean;
  data?: IOption[];
  status?: Status;
};

export const useGetCities = (): UseGetCitiesResp => {
  const { data, isLoading } = useQuery(["catalog-cities"], () => getCities());
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
  };
};
