import { businessApiClient } from "../businessApiClient";
import { useMutation } from "react-query";

type AssignBusinessTaskReq = {
  taskId: string;
  doerId: string;
  price: number;
};

type AssignBusinessTaskResp = {
  success: boolean;
};

export const assignBusinessTask = (
  data: AssignBusinessTaskReq,
): Promise<AssignBusinessTaskResp> =>
  businessApiClient.post("/task/assign", data).then((resp) => resp.data);

export const useAssignBusinessTask = (
  onSuccess?: (data: AssignBusinessTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(assignBusinessTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
