import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

type CreateDoerReq = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  cityId: number;
  aboutMe: string;
  availability: string;
  iban: string;
  note: string;
  skillIds: number[];
  isTrusted: true;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
};

const createDoer = (data: CreateDoerReq): Promise<string> =>
  apiClient.post("/doer/create", data).then((resp) => resp.data);

export const useCreateDoer = (
  onSuccess?: (data: string) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(createDoer, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
