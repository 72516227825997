import { GeneralResponseType } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useQuery, UseQueryOptions } from "react-query";

type PresetTask = {
  presetId: number;
  iconUrl: string;
  name: string;
  shortDescription: string;
  categoryId: number;
  colorHex: string;
};

type GetAllPresetTasksResp = GeneralResponseType<PresetTask[]>;

const getAllPresetTasks = (): Promise<GetAllPresetTasksResp> =>
  apiClient.get("/task/allPresetTasks").then((resp) => resp.data);

export const useGetAllPresetTasks = (
  options?: UseQueryOptions<GetAllPresetTasksResp>,
) => {
  const { data, isLoading } = useQuery({
    queryKey: ["task-all-preset-tasks"],
    queryFn: getAllPresetTasks,
    ...options,
  });
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
  };
};
