import { GeneralResponseType } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useQuery, UseQueryOptions } from "react-query";

type Category = {
  id: number;
  name: string;
};

type GetTaskCategoriesResp = GeneralResponseType<Category[]>;

const getTaskCategories = (): Promise<GetTaskCategoriesResp> =>
  apiClient.get("/task/categories").then((resp) => resp.data);

export const useGetTaskCategories = (
  options?: UseQueryOptions<GetTaskCategoriesResp>,
) => {
  const { data, isLoading } = useQuery({
    queryKey: ["task-categories"],
    queryFn: getTaskCategories,
    ...options,
  });
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
  };
};
