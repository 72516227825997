import { GeneralResponseType } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useQuery, UseQueryOptions } from "react-query";

export type ReviewData = {
  id: string;
  rating: number;
  comment: string;
  commenterName: string;
  commenterAvatar: string;
  status: number;
};

type Review = {
  data: ReviewData[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
};

type GetReviewsResp = GeneralResponseType<Review>;

export const getReviews = (
  currentPage: number,
  pageSize: number,
  reviewStatus: number,
): Promise<GetReviewsResp> => {
  return apiClient
    .post("/review/filter", {
      pageNumber: currentPage,
      pageSize: pageSize,
      status: reviewStatus,
    })
    .then((resp) => resp.data);
};

export const useGetReviews = (
  currentPage: number,
  pageSize: number,
  reviewStatus: number,
  options?: UseQueryOptions<GetReviewsResp>,
) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["review-filter", { currentPage, pageSize, reviewStatus }],
    queryFn: () => getReviews(currentPage, pageSize, reviewStatus),
    ...options,
  });
  return {
    isLoading,
    reviews: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};
