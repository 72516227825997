import { GeneralResponseType, IOption } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useQuery, UseQueryOptions } from "react-query";

export type TaskDoer = {
  //doer in task details
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  aboutMe: string;
  //doer active?
  //doer available days?
  reviews: [
    {
      id: string;
      rating: number;
      comment: string;
      commenterName: string;
      commenterAvatar: string;
    },
  ];
  isTrusted: boolean;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
};

export type TaskDetails = {
  id: string;
  //clinet phone number?
  adminId: string;
  taskStatus: number;
  title: string;
  description: string;
  clientId: string;
  clientName: string;
  address: string;
  city: IOption;
  internalComment: string;
  proposedStartTime: number;
  proposedDuration: number;
  phoneNumber: string;
  doer: TaskDoer;
  price: number;
  transactionId: string;
  transactionUrl: string;
  actualStartTime: number;
  cancellationReason: {
    id: number;
    name: string;
    isTerminating: boolean;
  };
  isDirectTransfer: boolean;
  images: string[];
  createdTimestamp: string;
};

type GetTaskDetailsResp = GeneralResponseType<TaskDetails>;

export const getTaskDetails = (
  id?: number | string,
): Promise<GetTaskDetailsResp> | null => {
  if (id) {
    return apiClient.get(`/task/${id}`).then((resp) => resp.data);
  }
  return null;
};

export const useGetTaskDetails = (
  id?: number | string,
  options?: UseQueryOptions<GetTaskDetailsResp | null>,
) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["task-id", { id }],
    queryFn: () => getTaskDetails(id),
    ...options,
  });
  return {
    isLoading,
    task: data?.data,
    status: data?.status,
    refetch,
  };
};
