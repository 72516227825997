import React, { useMemo, useState } from "react";
import "./CategoriesStep.scss";
import { CustomSelect } from "../../../../../../components/CustomSelect/CustomSelect";
import ImageErrorFallback from "../../../../../../assets/png/ImageErrorFallback.png";
import { onImageError } from "../../../../../../utils/helpers";
import { Form, FormInstance } from "antd";
import { useGetTaskCategories } from "../../../../../../services/retail/task/useGetTaskCategories";
import { useGetAllPresetTasks } from "../../../../../../services/retail/task/useGetAllPresetTasks";

type CategoriesStepProps = {
  form: FormInstance;
};

export const CategoriesStep: React.FC<CategoriesStepProps> = ({ form }) => {
  const { data: categories, isLoading: categoriesLoading } =
    useGetTaskCategories();
  const { data: allPresetTasks } = useGetAllPresetTasks();
  const presetTaskId = Form.useWatch("PresetTaskId", form);
  const selectedCategoryId = Form.useWatch("SelectedCategoryId", form);

  const modifiedCategories = useMemo(() => {
    if (categories) {
      return [
        {
          id: 0,
          name: "ყველა კატეგორია",
        },
        ...categories,
      ];
    }
    return categories;
  }, [categories]);

  const filteredPresetTasks = useMemo(() => {
    if (selectedCategoryId !== 0) {
      return allPresetTasks?.filter(
        (presetTask) => presetTask.categoryId === selectedCategoryId,
      );
    }
    return allPresetTasks;
  }, [selectedCategoryId, allPresetTasks]);

  return (
    <div className={"categories-step-container"}>
      <Form.Item name={"SelectedCategoryId"}>
        <CustomSelect
          options={modifiedCategories}
          placeholder={"კატეგორია"}
          loading={categoriesLoading}
          className={"category-select"}
        />
      </Form.Item>
      <Form.Item name={"PresetTaskId"} hidden />
      <div className={"preset-tasks-container"}>
        {filteredPresetTasks?.map((presetTask) => {
          return (
            <div
              key={presetTask.presetId}
              title={presetTask.shortDescription}
              className={`preset-task-item ${
                presetTaskId === presetTask.presetId ? "selected" : ""
              }`}
              onClick={() => {
                form.setFieldsValue({ PresetTaskId: presetTask.presetId });
              }}
            >
              <img
                src={presetTask.iconUrl || ImageErrorFallback}
                onError={onImageError}
                alt={"icon"}
                className={"preset-task-icon"}
              />
              <div className={"preset-task-info-wrapper"}>
                <div className={"preset-task-name"}>{presetTask.name}</div>
                <div>{presetTask.shortDescription}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
