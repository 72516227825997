import React, { useEffect } from "react";
import "./EditDetailsModal.scss";
import { Col, Form, Input, Modal, Row } from "antd";
import { formValidation } from "../../../../../utils/formValidation";
import { CustomSelect } from "../../../../../components/CustomSelect/CustomSelect";
import { doerTypes } from "../../../../../services/generalTypes";
import { useGetCities } from "../../../../../services/retail/catalog/useGetCities";

interface IEditDetailsModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues: {
    cityId?: number;
    phoneNumber?: string;
    doerType?: number;
    avaibility?: string;
    iban?: string;
  };
  onSubmit?: (values: any, afterSubmit?: () => void) => void;
  loading?: boolean;
}

export const EditDetailsModal: React.FC<IEditDetailsModal> = ({
  modalOpen,
  setModalOpen,
  initialValues,
  onSubmit,
  loading,
}) => {
  const { isLoading: citiesLoading, data: cities } = useGetCities();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!modalOpen) {
      form.resetFields();
    }
  }, [form, modalOpen]);

  useEffect(() => {
    if (modalOpen) {
      form.setFieldsValue({
        ...initialValues,
      });
    }
  }, [initialValues, modalOpen]);

  const handleSubmit = (values: any) => {
    onSubmit?.(values, () => setModalOpen(false));
  };

  return (
    <Modal
      open={modalOpen}
      className={"edit-details-modal"}
      title={"დეტალების რედაქტირება"}
      onOk={form.submit}
      okText={"დასტური"}
      cancelText={"დახურვა"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: loading,
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={"cityId"} rules={formValidation.required}>
              <CustomSelect
                options={cities}
                placeholder={"ქალაქი"}
                loading={citiesLoading}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"phoneNumber"} rules={formValidation.required}>
              <Input
                className={"edit-doer-input"}
                placeholder={"ტელეფონის ნომერი"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name={"doerType"} rules={formValidation.required}>
              <CustomSelect
                options={doerTypes}
                placeholder={"შემსრულებლის ტიპი"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={"avaibility"} rules={formValidation.required}>
              <Input
                className={"edit-doer-input"}
                placeholder={"ხელმისაწვდომობა"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"iban"} rules={formValidation.required}>
              <Input
                className={"edit-doer-input"}
                placeholder={"ბანკის ანგარიში"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
