import { businessApiClient } from "../businessApiClient";
import { useMutation } from "react-query";

type UpdateBusinessTaskReq = {
  taskId: string;
  address?: string;
  title?: string;
  description?: string;
  doerId?: string;
  price?: number;
};

export const updateBusinessTask = (
  data: UpdateBusinessTaskReq,
): Promise<void> =>
  businessApiClient.post("/task/update", data).then((resp) => resp.data);

export const useUpdateBusinessTask = (
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(updateBusinessTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
