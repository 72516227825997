import React, { useMemo, useState } from "react";
import "./BusinessTasks.scss";
import { Table } from "antd";
import { getDataSource } from "./table/datasource";
import { getColumns } from "./table/columns";
import { TaskStatusButton } from "../../../components/TaskStatusButton/TaskStatusButton";
import { useNavigate, useParams } from "react-router";
import {
  getBusinessStatusColorById,
  getBusinessStatusIcon,
  getBusinessStatusNameById,
} from "../../../utils/helpers";
import { CustomSelect } from "../../../components/CustomSelect/CustomSelect";
import { useBusinessTaskFilters } from "./hooks/useBusinessTaskFilters";
import { useBusinessTaskStatus } from "./hooks/useBusinessTaskStatus";
import { useTablePagination } from "../../../hooks/useTablePagination";
import { SearchInput } from "../../../components";
import { useDebouncedCallback } from "use-debounce";
import { usePaginatedCompanies } from "../../../hooks/usePaginatedCompanies";
import { useGetBusinessTasks } from "../../../services/business/task/useGetBusinessTasks";

export const BusinessTasks = () => {
  const { status: paramsStatus } = useParams();
  const navigate = useNavigate();
  const { pagination, resetPagination, resetCurrentPage } =
    useTablePagination(8);
  const { statusesArr, selectedTab, businessTaskStatuses } =
    useBusinessTaskStatus(paramsStatus);

  const [companySearchText, setCompanySearchText] = useState<
    string | undefined
  >();

  const {
    companyOptions,
    loadMoreCompanies,
    companiesLoading,
    hasMoreCompanies,
    pageNumber: companyPageNumber,
    resetPagination: resetCompanyPagination,
  } = usePaginatedCompanies({ searchText: companySearchText });

  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();
  const [searchText, setSearchText] = useState<string | undefined>();

  const {
    data: businessTasks,
    isLoading: businessTasksLoading,
    totalItemCount,
  } = useGetBusinessTasks({
    pageNumber: pagination.currentPage,
    pageSize: pagination.pageSize,
    statuses: businessTaskStatuses,
    companyId: selectedCompany,
    searchText,
  });

  const { filteredBusinessTasks } = useBusinessTaskFilters(businessTasks);

  const tableDataSource = useMemo(() => {
    if (filteredBusinessTasks) {
      return getDataSource(filteredBusinessTasks);
    }
    return [];
  }, [filteredBusinessTasks]);

  const tableColumns = useMemo(() => {
    return getColumns(selectedTab);
  }, [selectedTab]);

  const onBusinessTaskSearch = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (pagination.currentPage !== 1) {
        resetCurrentPage();
      }
      setSearchText(e.target.value);
    },
    200,
  );

  const onCompanyDropdownScroll = (e: UIEvent) => {
    const target = e.target as HTMLDivElement;

    if (
      target.scrollTop + target.clientHeight >= target.scrollHeight &&
      hasMoreCompanies &&
      !companiesLoading
    ) {
      loadMoreCompanies();
    }
  };

  const onCompanySearch = useDebouncedCallback((value: string) => {
    if (companyPageNumber !== 1) {
      resetCompanyPagination();
    }
    setCompanySearchText(value);
  }, 200);

  return (
    <div className={"business-tasks-container"}>
      <div className={"statuses-wrapper"}>
        {statusesArr.map((item) => {
          const id = item.id;
          const selected = item.name === selectedTab;
          const statusColor = getBusinessStatusColorById(id);
          const name = getBusinessStatusNameById(id);
          const icon = getBusinessStatusIcon(id);
          return (
            <TaskStatusButton
              name={name}
              icon={icon}
              color={statusColor}
              statusItem={item}
              selected={selected}
              key={id}
              onClick={() => {
                resetPagination();
                navigate(`/business/${item.name}`);
              }}
            />
          );
        })}
      </div>
      <div className={"filters-container"}>
        <SearchInput onChange={onBusinessTaskSearch} />
        <CustomSelect
          value={selectedCompany}
          options={[...(companyOptions || [])]}
          placeholder={"კომპანია"}
          loading={companiesLoading}
          onChange={(value: string) => {
            setSelectedCompany(value);
            resetPagination();
          }}
          showSearch
          allowClear
          filterOption={false}
          onSearch={onCompanySearch}
          onClear={() => {
            setCompanySearchText(undefined);
            resetCompanyPagination();
          }}
          onSelect={(value: string) => {
            if (value === selectedCompany) {
              setSelectedCompany(undefined);
            }
          }}
          onPopupScroll={onCompanyDropdownScroll}
        />
      </div>
      <Table
        className={"business-tasks-table"}
        dataSource={tableDataSource}
        columns={tableColumns}
        loading={businessTasksLoading}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`/business/${selectedTab}/${record.id}`);
            },
          };
        }}
        pagination={{
          ...pagination,
          total: totalItemCount || 1,
        }}
      />
    </div>
  );
};
