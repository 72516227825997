import { BusinessTaskStatus } from "../../generalTypes";
import { businessApiClient } from "../businessApiClient";
import qs from "qs";
import { useQuery, UseQueryOptions } from "react-query";

export type BusinessTaskData = {
  id: string;
  status: BusinessTaskStatus;
  senderUserId: string;
  companyId: string;
  companyName: string;
  address: string;
  title: string;
  description: string;
  doerId: string | null;
  doerPrice: number | null;
  materialPrice: number | null;
  companyProfit: number | null;
  createdOn: Date;
};

type BusinessTasks = {
  data: BusinessTaskData[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
};

type BusinessTasksResp = {
  tasksList: BusinessTasks;
};

type BusinessTasksParams = {
  companyId?: string;
  searchText?: string;
  statuses?: number[];
  creationStart?: string;
  creationEnd?: string;
  completionStart?: string;
  completionEnd?: string;
  pageNumber?: number;
  pageSize?: number;
};

export const getBusinessTasks = (
  params?: BusinessTasksParams,
): Promise<BusinessTasksResp> =>
  businessApiClient
    .get(`internal/task/all`, {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    })
    .then((resp) => resp.data);

export const useGetBusinessTasks = (
  params?: BusinessTasksParams,
  options?: UseQueryOptions<BusinessTasksResp>,
) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["business-task-all", params],
    queryFn: () => getBusinessTasks(params),
    ...options,
  });
  return {
    isLoading,
    data: data?.tasksList?.data,
    totalItemCount: data?.tasksList?.totalItemCount,
    page: data?.tasksList?.page,
    offset: data?.tasksList?.offset,
    pageCount: data?.tasksList?.pageCount,
    refetch,
  };
};
