import { GeneralResponseType } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

export type SendOtpReq = {
  phoneNumber: string;
};

export type HashData = {
  hash: string | null;
};

type SendOtpResp = GeneralResponseType<HashData>;

const sendOtpCode = (data: SendOtpReq): Promise<SendOtpResp> =>
  apiClient.post("/otp/gosmsOtpRequest", data).then((resp) => {
    return resp.data;
  });

export const useSendOtpCode = (
  onSuccess?: (data: SendOtpResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(sendOtpCode, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
