import React, { useState } from "react";
import "./Auth.scss";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Logo } from "../../assets/svg";
import { setToken } from "../../utils/helpers";
import { OTPModal } from "./OTPModal/OTPModal";
import { useNavigate } from "react-router";
import { useSendOtpCode } from "../../services/retail/otp";
import { useVerifyOtpCode } from "../../services/retail/auth";

export const Auth: React.FC = () => {
  const [form] = Form.useForm();

  const [otpModalOpen, setOtpModalOpen] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<boolean>(false);
  const navigate = useNavigate();

  const [verifyData, setVerifyData] = useState<string | null>();

  const { mutate: sendOtpCode, isLoading: sendOtpCodeLoading } = useSendOtpCode(
    ({ data }) => setVerifyData(data.hash),
    () => message.error("დაფიქსირდა შეცდომა"),
  );

  const { mutate: verifyOtpCode, isLoading: verifyOtpCodeLoading } =
    useVerifyOtpCode(({ data }) => setToken(data.accessToken));

  const handleClick = () => {
    const phone = form.getFieldValue("phone");
    if (phone) {
      sendOtpCode(
        {
          phoneNumber: `995${phone}`,
        },
        {
          onSuccess: () => setOtpModalOpen(true),
        },
      );
    }
  };
  const handleOtpSubmit = (code: string) => {
    if (verifyData) {
      verifyOtpCode(
        {
          hash: verifyData,
          code,
          phoneNumber: `995${form.getFieldValue("phone")}`,
        },
        {
          onSuccess: () => {
            setOtpModalOpen(false);
            navigate("/tasks");
          },
          onError: () => {
            setOtpError(true);
          },
        },
      );
    }
  };

  return (
    <div className={"auth-layout"}>
      <Form form={form} className={"auth-layout-form"} onFinish={handleClick}>
        <Logo />
        <Form.Item
          name={"phone"}
          rules={[
            {
              required: true,
              message: "ველი სავალდებულოა",
            },
            {
              pattern: /^5[0-9]{8}/,
              message: "ტელეფონის ნომერი არასწორია",
            },
          ]}
        >
          <Input
            type={"tel"}
            className={"auth-input"}
            placeholder={"ტელეფონის ნომერი"}
            onChange={(e) => {
              if (e.target.value?.length > 9) {
                form.setFieldsValue({ phone: e.target.value.slice(0, 9) });
                form.validateFields();
              }
            }}
          />
        </Form.Item>
        <Button
          className={"send-otp-button"}
          loading={sendOtpCodeLoading}
          onClick={form.submit}
        >
          კოდის გამოგზავნა
        </Button>
        <Checkbox className={"remember-me-checkbox"} checked>
          {"დამახსოვრება"}
        </Checkbox>
        {otpModalOpen && (
          <OTPModal
            modalOpen={otpModalOpen}
            setModalOpen={setOtpModalOpen}
            onSubmit={handleOtpSubmit}
            loading={verifyOtpCodeLoading}
            hasError={otpError}
            setOtpError={setOtpError}
            phoneNumber={form.getFieldValue("phone")}
            sendOtp={sendOtpCode}
          />
        )}
      </Form>
    </div>
  );
};
