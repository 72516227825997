import { useMutation } from "react-query";
import { apiClient } from "../apiClient";

type UpdateTaskReq = {
  taskId: string;
  status?: number;
  title?: string;
  address?: string;
  cityId?: number;
  description?: string;
  senderType?: number;
  internalComment?: string;
  proposedStartTime?: number;
  proposedDuration?: number;
  doerId?: string;
  price?: number;
  transactionId?: string;
  transactionUr?: string;
};

const updateTask = (data: UpdateTaskReq): Promise<void> =>
  apiClient
    .post("/task/update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp) => resp.data);

export const useUpdateTask = (onSuccess?: () => void, onError?: () => void) => {
  const { isLoading, mutate } = useMutation(updateTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
