import { businessApiClient } from "../businessApiClient";
import qs from "qs";
import { useQuery, UseQueryOptions } from "react-query";
import { DoersParams } from "../../retail/doer/useGetDoers";
import { Doer } from "../../shared-types";

type BusinessDoers = {
  data: Doer[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
};

export type GetBusinessDoersResp = {
  doerList: BusinessDoers;
};

type BusinessDoersParams = {
  searchText?: string;
  pageNumber?: number;
  pageSize?: number;
  skills?: (string | number)[];
  cityId?: number;
};

export const getBusinessDoers = (
  params?: BusinessDoersParams,
): Promise<GetBusinessDoersResp> => {
  return businessApiClient
    .get("/doer/filter", {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    })
    .then((resp) => resp.data);
};

export type UseGetBusinessDoersResp = {
  isLoading: boolean;
  doers?: BusinessDoers;
  refetch: any;
  isRefetching: boolean;
};

export const useGetBusinessDoers = (
  params?: DoersParams,
  options?: UseQueryOptions<GetBusinessDoersResp>,
): UseGetBusinessDoersResp => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["business-doer-filter", params],
    queryFn: () => getBusinessDoers(params),
    enabled: true,
    ...options,
  });
  return {
    isLoading,
    doers: data?.doerList,
    refetch,
    isRefetching,
  };
};
