import React, { useEffect, useMemo } from "react";
import "./EditDetailsModal.scss";
import { Col, DatePicker, Form, Input, Modal, Row } from "antd";
import { formValidation } from "../../../../../utils/formValidation";
import { CustomSelect } from "../../../../../components/CustomSelect/CustomSelect";
import {
  secondsToDays,
  secondsToHours,
  secondsToMinutes,
} from "../../../../../utils/helpers";
import moment from "moment";
import { useGetCities } from "../../../../../services/retail/catalog/useGetCities";

interface IEditDetailsModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues: {
    cityId?: number;
    address?: string;
    price?: number;
    proposedStartTime?: number;
    proposedDuration?: number;
    transactionId?: string;
    transactionUrl?: string;
  };
  onSubmit?: (values: any, afterSubmit?: () => void) => void;
  loading?: boolean;
}

export const EditDetailsModal: React.FC<IEditDetailsModal> = ({
  modalOpen,
  setModalOpen,
  initialValues,
  onSubmit,
  loading,
}) => {
  const { isLoading: citiesLoading, data: cities } = useGetCities();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!modalOpen) {
      form.resetFields();
    }
  }, [form, modalOpen]);

  useEffect(() => {
    if (modalOpen) {
      form.setFieldsValue({
        ...initialValues,
        days: initialValues.proposedDuration
          ? secondsToDays(initialValues.proposedDuration)
          : undefined,
        hours: initialValues.proposedDuration
          ? secondsToHours(initialValues.proposedDuration)
          : undefined,
        minutes: initialValues.proposedDuration
          ? secondsToMinutes(initialValues.proposedDuration)
          : undefined,
        proposedStartTime: initialValues.proposedStartTime
          ? moment.unix(initialValues.proposedStartTime)
          : undefined,
      });
    }
  }, [initialValues, modalOpen]);

  const handleSubmit = (values: any) => {
    const hasProposedDuration =
      Object.hasOwn(values, "days") &&
      Object.hasOwn(values, "hours") &&
      Object.hasOwn(values, "minutes");

    const hasProposedTime = Object.hasOwn(values, "proposedStartTime");

    const newValues = { ...values };

    if (hasProposedDuration) {
      newValues.proposedDuration =
        values.days * 3600 * 24 + values.hours * 3600 + values.minutes * 60;
    }
    if (hasProposedTime) {
      newValues.proposedStartTime = values.proposedStartTime.unix();
    }
    delete newValues.days;
    delete newValues.hours;
    delete newValues.minutes;

    onSubmit?.(newValues, () => setModalOpen(false));
  };

  const days = useMemo(() => {
    const arr = [];
    for (let i = 0; i <= 10; i++) {
      arr.push({ id: i, name: i.toString() });
    }
    return arr;
  }, []);

  const hours = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 24; i++) {
      arr.push({ id: i, name: i.toString() });
    }
    return arr;
  }, []);

  const minutes = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 60; i++) {
      if (i % 15 === 0) {
        arr.push({ id: i, name: i.toString() });
      }
    }
    return arr;
  }, []);

  return (
    <Modal
      open={modalOpen}
      className={"edit-details-modal"}
      title={"დეტალების რედაქტირება"}
      onOk={form.submit}
      okText={"დასტური"}
      cancelText={"დახურვა"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: loading,
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        {initialValues?.cityId || initialValues?.address ? (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={"cityId"} rules={formValidation.required}>
                <CustomSelect
                  options={cities}
                  placeholder={"ქალაქი"}
                  loading={citiesLoading}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"address"} rules={formValidation.required}>
                <Input
                  className={"edit-task-input"}
                  placeholder={"მისამართი"}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {initialValues?.price ? (
          <Row>
            <Col span={24}>
              <Form.Item name={"price"} rules={formValidation.required}>
                <Input
                  className={"edit-task-input"}
                  placeholder={"ფასი"}
                  suffix={"₾"}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {initialValues?.proposedDuration ? (
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name={"days"}>
                <CustomSelect
                  suffixIcon={"(დღე)"}
                  className={"edit-task-input"}
                  options={days}
                  placeholder={"დღე"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={"hours"}>
                <CustomSelect
                  suffixIcon={"(საათი)"}
                  className={"edit-task-input"}
                  options={hours}
                  placeholder={"საათი"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={"minutes"}>
                <CustomSelect
                  suffixIcon={"(წუთი)"}
                  className={"edit-task-input"}
                  options={minutes}
                  placeholder={"წუთი"}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {initialValues?.proposedStartTime ? (
          <Row>
            <Col span={24}>
              <Form.Item
                name={"proposedStartTime"}
                rules={formValidation.required}
              >
                <DatePicker
                  className={"edit-task-input"}
                  placeholder={"დაწყების დრო"}
                  showTime={{
                    format: "HH:mm",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {initialValues?.transactionId ? (
          <Row>
            <Col span={24}>
              <Form.Item name={"transactionId"}>
                <Input
                  className={"edit-task-input"}
                  placeholder={"ტრანზაქციის ID"}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {initialValues?.transactionUrl ? (
          <Row>
            <Col span={24}>
              <Form.Item name={"transactionUrl"}>
                <Input
                  className={"edit-task-input"}
                  placeholder={"ტრანზაქციის ლინკი"}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
};
