import { businessApiClient } from "../businessApiClient";
import { useMutation } from "react-query";
import { CheckEmailData } from "./useCheckEmail";

type RegisterWithLinkReq = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export type UserId = string | null;

type RegisterWithLinkData = {
  message: string;
  userId: UserId;
};

const registerWithLink = (
  data: RegisterWithLinkReq,
): Promise<RegisterWithLinkData> =>
  businessApiClient
    .post("/auth/register-with-link", data)
    .then((resp) => resp.data);

export const useRegisterWithLink = (
  onSuccess?: (data: CheckEmailData) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(registerWithLink, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
