import React, { useMemo, useState } from "react";
import "./AddCompanyModal.scss";
import { Form, message, Modal } from "antd";
import { ValueOf } from "../../../../utils/type-helpers";
import { EmailInputStep } from "./steps/EmailInputStep";
import { AddUserStep } from "./steps/AddUserStep";
import { AddCompanyStep } from "./steps/AddCompanyStep";
import { UserId } from "../../../../services/business/auth";

type AddCompanyModalProps = {
  modalOpen: boolean;
  onCloseModal: () => void;
  refetchCompanies: () => Promise<any>;
};

const COMPANY_STEPS = {
  EMAIL: "email",
  ADD_USER: "add_user",
  ADD_COMPANY: "add_company",
} as const;

type CompanyStep = ValueOf<typeof COMPANY_STEPS>;

export const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  modalOpen,
  onCloseModal,
  refetchCompanies,
}) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState<CompanyStep>(COMPANY_STEPS.EMAIL);
  const [adminUserId, setAdminUserId] = useState<UserId>(null);

  const onEmailCheckSuccess = (userId: UserId) => {
    setAdminUserId(userId);
    if (userId) {
      setStep(COMPANY_STEPS.ADD_COMPANY);
    } else {
      setStep(COMPANY_STEPS.ADD_USER);
    }
  };

  const onAddUserSuccess = (userId: UserId) => {
    setAdminUserId(userId);
    setStep(COMPANY_STEPS.ADD_COMPANY);
  };

  const onAddCompanySuccess = () => {
    refetchCompanies()
      .then(() => {
        onCloseModal();
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const currentStep = useMemo(() => {
    switch (step) {
      case COMPANY_STEPS.ADD_COMPANY:
        return (
          <AddCompanyStep
            onAddCompanySuccess={onAddCompanySuccess}
            adminUserId={adminUserId}
          />
        );
      case COMPANY_STEPS.ADD_USER:
        return <AddUserStep onAddUserSuccess={onAddUserSuccess} />;
      default:
        return <EmailInputStep onEmailCheckSuccess={onEmailCheckSuccess} />;
    }
  }, [step, adminUserId]);

  return (
    <Modal
      open={modalOpen}
      className={"add-company-modal"}
      title={"კომპანიის დამატება"}
      cancelText={"გაუქმება"}
      onCancel={onCloseModal}
      maskClosable
      destroyOnClose
      centered
      footer={<></>}
      afterClose={() => {
        form.resetFields();
        setAdminUserId(null);
        setStep(COMPANY_STEPS.EMAIL);
      }}
    >
      <Form form={form}>{currentStep}</Form>
    </Modal>
  );
};
