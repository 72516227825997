import { businessApiClient } from "../businessApiClient";
import { useMutation } from "react-query";

type CancelBusinessTaskResp = {
  success: boolean;
};

export const cancelBusinessTask = (
  taskId?: string,
): Promise<CancelBusinessTaskResp> =>
  businessApiClient.post(`/task/cancel/${taskId}`).then((resp) => resp.data);

export const useCancelBusinessTask = (
  onSuccess?: (data: CancelBusinessTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(cancelBusinessTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
