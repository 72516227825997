import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

type UpdateReviewStatusReq = {
  id: string;
  status: number;
};

export const updateReviewStatus = (
  data: UpdateReviewStatusReq,
): Promise<void> =>
  apiClient.post("/review/update", data).then((resp) => resp.data);

export const useUpdateReviewStatus = (
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(updateReviewStatus, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
