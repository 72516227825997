import { businessApiClient } from "../businessApiClient";
import { useQuery, UseQueryOptions } from "react-query";

export type CompanyData = {
  id: string;
  taxCode: string;
  name: string;
  legalAddress: string;
  actualAddress: string;
  adminUserId: string;
  email: string;
  cityName: string;
};

type Companies = {
  data: CompanyData[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
};

type CompaniesResp = {
  companiesList: Companies;
};

type CompaniesParams = {
  searchText?: string;
  pageNumber?: number;
  pageSize?: number;
};

export const getCompanies = (
  params?: CompaniesParams,
): Promise<CompaniesResp> =>
  businessApiClient.get(`/company/all`, { params }).then((resp) => resp.data);

export const useGetCompanies = (
  params?: CompaniesParams,
  options?: UseQueryOptions<CompaniesResp>,
) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["business-company-all", params],
    queryFn: () => getCompanies(params),
    ...options,
  });

  return {
    isLoading,
    data: data?.companiesList?.data,
    totalItemCount: data?.companiesList?.totalItemCount,
    page: data?.companiesList?.page,
    offset: data?.companiesList?.offset,
    pageCount: data?.companiesList?.pageCount,
    refetch,
  };
};
