import { IOption } from "../../generalTypes";
import { businessApiClient } from "../businessApiClient";
import { useQuery } from "react-query";

type GetBusinessSkillsResp = {
  skills: IOption[];
};

export const getBusinessSkills = (): Promise<GetBusinessSkillsResp> =>
  businessApiClient.get("/catalog/skills").then((resp) => resp.data);

export type UseGetBusinessSkillsResp = {
  isLoading: boolean;
  data?: IOption[];
  refetch: any;
  isRefetching: boolean;
};

export const useGetBusinessSkills = (): UseGetBusinessSkillsResp => {
  const { data, isLoading, refetch, isRefetching } = useQuery(
    ["business-catalog-skills"],
    getBusinessSkills,
  );
  return {
    isLoading,
    data: data?.skills,
    refetch,
    isRefetching,
  };
};
