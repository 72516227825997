import React, { useState } from "react";
import "./DoerContent.scss";
import { message } from "antd";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  AddCommentModal,
  ContainerWithEdit,
  InternalComment,
  SectionTitle,
} from "../../../../../components";
import EmptyAvatar from "../../../../../assets/png/EmptyAvatar.png";
import {
  formatPhoneNumber,
  getDoerTypeName,
  onImageError,
} from "../../../../../utils/helpers";
import { EditNameModal } from "../../ActionModals/EditNameModal/EditNameModal";
import EmptyCompany from "../../../../../assets/png/EmptyCompany.png";
import { EditDetailsModal } from "../../ActionModals/EditDetailsModal/EditDetailsModal";
import { EditSkillsModal } from "../../ActionModals/EditSkillsModal/EditSkillsModal";
import { EditAboutMeModal } from "../../ActionModals/EditAboutMeModal/EditAboutMeModal";
import { DoerDetails } from "../../../../../services/shared-types";
import { useUpdateDoer } from "../../../../../services/retail/doer/useUpdateDoer";

interface IDoerContent {
  doer: DoerDetails;
  canAddComment?: boolean;
  refetch: () => Promise<any>;
}

export const DoerContent: React.FC<IDoerContent> = ({
  canAddComment = true,
  doer,
  refetch,
}) => {
  const [editNameModalOpen, setEditNameModalOpen] = useState<boolean>(false);
  const [editDetailsModalOpen, setEditDetailsModalOpen] =
    useState<boolean>(false);
  const [editSkillsModalOpen, setEditSkillsModalOpen] =
    useState<boolean>(false);
  const [editAboutMeModal, setEditAboutMeModal] = useState<boolean>(false);
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);

  const { mutate: mutateUpdateDoer, isLoading: updateDoerLoading } =
    useUpdateDoer();

  const handleSubmit = (values: any, afterSubmit?: () => void) => {
    mutateUpdateDoer(
      { doerId: doer.id, ...values },
      {
        onSuccess: () => {
          afterSubmit?.();
          refetch().catch((err) => message.error(err));
        },
        onError: () => {
          message.error("დაფიქსირდა შეცდომა");
        },
      },
    );
  };

  const isCompany = doer.doerType === 2;

  const getDoerAvatarSrc = () => {
    if (isCompany) {
      return doer.companyImageUrl || EmptyCompany;
    }
    return doer.avatar || EmptyAvatar;
  };

  return (
    <>
      {doer ? (
        <div className={"doer-wrapper"}>
          <div className={"doer-info-wrapper"}>
            <img
              className="doer-image"
              src={getDoerAvatarSrc()}
              onError={onImageError}
              alt={""}
            />
            <div className={"doer-main-info"}>
              <ContainerWithEdit onEditClick={() => setEditNameModalOpen(true)}>
                <div className={"doer-name-wrapper"}>
                  <div className={"doer-name"}>
                    {isCompany
                      ? `${doer.companyName}`
                      : `${doer.firstName} ${doer.lastName}`}
                  </div>
                  {doer.isTrusted && (
                    <CheckCircleOutlined className={"doer-verified-icon"} />
                  )}
                  {/*<Tooltip title={getDoerStatus(doer.status)}>*/}
                  {/*  /!*<div className={"doer-activity-status"} style={{backgroundColor: getDoerStatusColor(doer.status)}}/>*!/*/}
                  {/*</Tooltip>*/}
                </div>
              </ContainerWithEdit>
              {/*<div className={"doer-info doer-hourly-rate"}>*/}
              {/*  <DollarOutlined />*/}
              {/*  <div>{doer.hourlyRate}</div>*/}
              {/*</div>*/}
              <ContainerWithEdit
                align={"flex-start"}
                onEditClick={() => setEditDetailsModalOpen(true)}
              >
                <div>
                  {doer.city && (
                    <div className={"doer-info doer-available-days"}>
                      <EnvironmentOutlined />
                      <div>ქალაქი:</div>
                      <div>{doer.city.name}</div>
                    </div>
                  )}
                  {doer.phoneNumber && (
                    <div className={"doer-info doer-phone-number"}>
                      <PhoneOutlined />
                      <div>ტელეფონის ნომერი:</div>
                      <div>{formatPhoneNumber(doer.phoneNumber)}</div>
                    </div>
                  )}
                  {doer.doerType && (
                    <div className={"doer-info doer-available-days"}>
                      <UserOutlined />
                      <div>შემსრულებლის ტიპი:</div>
                      <div>{getDoerTypeName(doer.doerType)}</div>
                    </div>
                  )}
                  {doer.avaibility && (
                    <div className={"doer-info doer-available-days"}>
                      <CalendarOutlined />
                      <div>ხელმისაწვდომობა:</div>
                      <div>{doer.avaibility}</div>
                    </div>
                  )}
                  {doer.iban && (
                    <div className={"doer-info doer-iban"}>
                      <CreditCardOutlined />
                      <div>ბანკის ანგარიში:</div>
                      <div>{doer.iban}</div>
                    </div>
                  )}
                </div>
              </ContainerWithEdit>
            </div>
          </div>

          {doer.skills?.length ? (
            <ContainerWithEdit
              onEditClick={() => setEditSkillsModalOpen(true)}
              className={"doer-categories-section-title"}
            >
              <SectionTitle title={"კატეგორიები"} withAddIcon={false} />
            </ContainerWithEdit>
          ) : (
            <SectionTitle
              title={"კატეგორიები"}
              withAddIcon
              onAddClick={() => setEditSkillsModalOpen(true)}
              iconColor={"#617585"}
            />
          )}
          <div className={"doer-categories-wrapper"}>
            {doer.skills.map((skill) => {
              return (
                <div key={skill.id} className={"category-item"}>
                  {skill.name}
                </div>
              );
            })}
          </div>

          {/*<div className={"section-wrapper"}>*/}
          {/*  <SectionTitle title={"აქტიური დავალებები"}/>*/}
          {/*  <div className={"active-tasks-wrapper"}>*/}
          {/*    {doer.tasks.filter((task) => task.status !== "finished").map((task) => {*/}
          {/*      return (*/}
          {/*        <TaskItem*/}
          {/*          task={task}*/}
          {/*          key={task.id}*/}
          {/*          onClick={() => navigate(`/tasks/${task.status}/${task.id}`)}*/}
          {/*        />*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className={"section-wrapper"}>*/}
          {/*  <SectionTitle title={"შესრულებული დავალებები"}/>*/}
          {/*  <div className={"active-tasks-wrapper"}>*/}
          {/*    {doer.tasks.filter((task) => task.status === "finished").map((task) => {*/}
          {/*      return (*/}
          {/*        <TaskItem*/}
          {/*          task={task} */}
          {/*          key={task.id}*/}
          {/*          onClick={() => navigate(`/tasks/${task.status}/${task.id}`)}*/}
          {/*        />*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*</div>*/}
          {doer.aboutMe && (
            <div className={"section-wrapper"}>
              <ContainerWithEdit
                onEditClick={() => setEditAboutMeModal(true)}
                className={"doer-categories-section-title"}
              >
                <SectionTitle title={"შემსრულებლის შესახებ"} />
              </ContainerWithEdit>
              <EditAboutMeModal
                modalOpen={editAboutMeModal}
                setModalOpen={setEditAboutMeModal}
                currentAboutMe={doer.aboutMe}
                loading={updateDoerLoading}
                onSubmit={handleSubmit}
              />
              <div>{doer.aboutMe}</div>
            </div>
          )}
          <div className={"section-wrapper"}>
            {doer.notes ? (
              <ContainerWithEdit
                className={"doer-categories-section-title"}
                onEditClick={() => setCommentModalOpen(true)}
              >
                <SectionTitle title={"შიდა კომენტარი"} withAddIcon={false} />
              </ContainerWithEdit>
            ) : (
              <SectionTitle
                title={"შიდა კომენტარი"}
                withAddIcon={canAddComment}
                onAddClick={() => setCommentModalOpen(true)}
                iconColor={"#617585"}
              />
            )}
            <AddCommentModal
              modalOpen={commentModalOpen}
              setModalOpen={setCommentModalOpen}
              color={"#617585"}
              currentComment={doer.notes}
              loading={updateDoerLoading}
              onSubmit={handleSubmit}
              commentValuePorpName={"note"}
            />
            {doer.notes && (
              <div className={"internal-comments-wrapper"}>
                <InternalComment comment={doer.notes} />
              </div>
            )}
          </div>
          <EditNameModal
            modalOpen={editNameModalOpen}
            setModalOpen={setEditNameModalOpen}
            currentFirstName={doer.firstName}
            currentLastName={doer.lastName}
            isCompany={isCompany}
            currentCompanyName={doer.companyName}
            isTrusted={doer.isTrusted}
            loading={updateDoerLoading}
            onSubmit={handleSubmit}
          />
          <EditDetailsModal
            modalOpen={editDetailsModalOpen}
            setModalOpen={setEditDetailsModalOpen}
            initialValues={{
              cityId: doer.city?.id ? +doer.city.id : undefined,
              phoneNumber: doer.phoneNumber,
              doerType: doer.doerType,
              avaibility: doer.avaibility,
              iban: doer.iban,
            }}
            loading={updateDoerLoading}
            onSubmit={handleSubmit}
          />
          <EditSkillsModal
            modalOpen={editSkillsModalOpen}
            setModalOpen={setEditSkillsModalOpen}
            currentSkills={doer.skills}
            loading={updateDoerLoading}
            onSubmit={handleSubmit}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
