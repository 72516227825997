import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

type UpdateDoerData = {
  doerId: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  avatar?: string;
  skills?: number[];
  cityId?: number;
  aboutMe?: string;
  avaibility?: string;
  iban?: string;
  note?: string;
  isTrusted?: true;
  doerType?: number;
  companyName?: string;
  companyImageUrl?: string;
};

const updateDoer = (data: UpdateDoerData): Promise<void> =>
  apiClient.post("/doer/update", data).then((resp) => resp.data);

export const useUpdateDoer = (onSuccess?: () => void, onError?: () => void) => {
  const { isLoading, mutate } = useMutation(updateDoer, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
