import { useQuery, UseQueryOptions } from "react-query";
import { apiClient } from "../apiClient";
import { GeneralResponseType, Status } from "../../generalTypes";
import { Doer } from "../../shared-types";

type Doers = {
  data: Doer[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
};

export type GetDoersResp = GeneralResponseType<Doers>;

export type DoersParams = {
  searchText?: string;
  pageNumber?: number;
  pageSize?: number;
  skillIds?: (string | number)[];
  cityId?: number;
};

const getDoers = (params?: DoersParams): Promise<GetDoersResp> => {
  return apiClient
    .post("/doer/filter", { ...params })
    .then((resp) => resp.data);
};

export type UseGetDoersResp = {
  isLoading: boolean;
  doers?: Doers;
  status?: Status;
  refetch: any;
  isRefetching: boolean;
};

export const useGetDoers = (
  params?: DoersParams,
  options?: UseQueryOptions<GetDoersResp>,
): UseGetDoersResp => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["doer-filter", params],
    queryFn: () => getDoers(params),
    enabled: true,
    ...options,
  });
  return {
    isLoading,
    doers: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};
