import React, { useMemo } from "react";
import "./FindDoerDetailsContent.scss";
import { Col, DatePicker, Form, FormInstance, Input, Row } from "antd";
import { formValidation } from "../../../utils/formValidation";
import { CustomSelect } from "../../CustomSelect/CustomSelect";
import { splitShares } from "../../../services/generalTypes";
import { Moment } from "moment";

type FindDoerDetailsContentProps = {
  form: FormInstance;
  onFinish: (formValues: FormValues) => void;
  hasAdditionalDetails?: boolean;
  hasSplitPrice?: boolean;
};

export type FormValues = {
  price: number;
  days: number;
  hours: number;
  minutes: number;
  proposedStartTime: Moment;
  splitShare: number;
};

export const FindDoerDetailsContent: React.FC<FindDoerDetailsContentProps> = ({
  form,
  onFinish,
  hasAdditionalDetails = true,
  hasSplitPrice,
}) => {
  const days = useMemo(() => {
    const arr = [];
    for (let i = 0; i <= 10; i++) {
      arr.push({ id: i, name: i.toString() });
    }
    return arr;
  }, []);

  const hours = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 24; i++) {
      arr.push({ id: i, name: i.toString() });
    }
    return arr;
  }, []);

  const minutes = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 60; i++) {
      if (i % 15 === 0) {
        arr.push({ id: i, name: i.toString() });
      }
    }
    return arr;
  }, []);

  return (
    <Form form={form} className={"find-doer-details"} onFinish={onFinish}>
      {hasSplitPrice ? (
        <Row gutter={16}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name={"doerPrice"}
                label={"შემსრულებლის ფასი(₾)"}
                labelCol={{ span: 24 }}
                rules={formValidation.required}
              >
                <Input
                  placeholder={"შემსრულებლის ფასი"}
                  className={"doer-details-input"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"materialPrice"}
                label={"მასალების ფასი(₾)"}
                labelCol={{ span: 24 }}
                rules={formValidation.required}
              >
                <Input
                  placeholder={"მასალების ფასი"}
                  className={"doer-details-input"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"companyProfit"}
                label={"კომპანიის სარგებელი(₾)"}
                labelCol={{ span: 24 }}
                rules={formValidation.required}
              >
                <Input
                  placeholder={"კომპანიის სარგებელი"}
                  className={"doer-details-input"}
                />
              </Form.Item>
            </Col>
          </Row>
        </Row>
      ) : (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={"price"}
              label={"ფასი(₾)"}
              labelCol={{ span: 24 }}
              rules={formValidation.required}
            >
              <Input placeholder={"ფასი"} className={"doer-details-input"} />
            </Form.Item>
          </Col>
        </Row>
      )}
      {hasAdditionalDetails && (
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name={"days"} rules={formValidation.required}>
                <CustomSelect
                  className={"doer-details-input"}
                  options={days}
                  placeholder={"დღე"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={"hours"} rules={formValidation.required}>
                <CustomSelect
                  className={"doer-details-input"}
                  options={hours}
                  placeholder={"საათი"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={"minutes"} rules={formValidation.required}>
                <CustomSelect
                  className={"doer-details-input"}
                  options={minutes}
                  placeholder={"წუთი"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"proposedStartTime"}
                rules={formValidation.required}
              >
                <DatePicker
                  className={"doer-details-input"}
                  placeholder={"დაწყების დრო"}
                  // onChange={(date) => console.log("date", date, moment(date).unix())}
                  showTime={{
                    format: "HH:mm",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"splitShare"} rules={formValidation.required}>
                <CustomSelect options={splitShares} placeholder={"საკომისიო"} />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
