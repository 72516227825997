import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

type RejectTaskReq = {
  taskId: string;
  reason: number;
  cancel: boolean;
};

const rejectTask = (data: RejectTaskReq): Promise<string> =>
  apiClient.post("/task/rejectOffer", data).then((resp) => resp.data);

export const useRejectTask = (onSuccess?: () => void, onError?: () => void) => {
  const { isLoading, mutate } = useMutation(rejectTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
