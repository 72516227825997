import { IOption } from "../../generalTypes";
import { businessApiClient } from "../businessApiClient";
import { useQuery } from "react-query";

type BusinessCitiesResp = {
  cities: IOption[];
};

export const getBusinessCities = (): Promise<BusinessCitiesResp> =>
  businessApiClient.get("/catalog/cities").then((resp) => resp.data);

export type UseGetBusinessCitiesResp = {
  isLoading: boolean;
  data?: IOption[];
};

export const useGetBusinessCities = (): UseGetBusinessCitiesResp => {
  const { data, isLoading } = useQuery(["business-catalog-cities"], () =>
    getBusinessCities(),
  );
  return {
    isLoading,
    data: data?.cities,
  };
};
