import { GeneralResponseType } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useQuery, UseQueryOptions } from "react-query";

export type TaskData = {
  id: string;
  taskStatus: number;
  title: string;
  description: string;
  clientId: string;
  clientName: string;
  doerId: string;
  address: string;
  city: string;
  createdDateTime: string;
};

export interface ITask {
  data: TaskData[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
}

type GetTasksResp = GeneralResponseType<ITask>;

export const getTasks = (
  currentPage: number,
  pageSize: number,
  taskStatuses: Array<string | number>,
): Promise<GetTasksResp> => {
  // const [_key, {currentPage, pageSize, taskStatuses}] = data.queryKey;
  return apiClient
    .post("/task/filter", {
      pageNumber: currentPage,
      pageSize: pageSize,
      taskStatuses: taskStatuses,
    })
    .then((resp) => resp.data);
};

export const useGetTasks = (
  currentPage: number,
  pageSize: number,
  taskStatuses: Array<string | number>,
  options?: UseQueryOptions<GetTasksResp>,
) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["task-filter", currentPage, pageSize, taskStatuses],
    queryFn: () => getTasks(currentPage, pageSize, taskStatuses),
    keepPreviousData: true,
    ...options,
  });
  return {
    isLoading,
    tasks: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};
