import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

type ClientCompleteTaskReq = {
  taskId: string;
  clientId: string;
};

const forceClientCompleteTask = (data: ClientCompleteTaskReq): Promise<void> =>
  apiClient
    .post("/task/forceClientCompleteTask", data)
    .then((resp) => resp.data);

export const useForceClientCompleteTask = (
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(forceClientCompleteTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
