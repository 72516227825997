import { BusinessTaskStatus } from "../../generalTypes";
import { businessApiClient } from "../businessApiClient";
import { useQuery, UseQueryOptions } from "react-query";

type BusinessTaskCompany = {
  id: string;
  name: string;
  number: string;
  city: string;
};

type BusinessTaskSender = {
  name: string;
  surname: string;
  phoneNumber: string;
  email: string;
};

export type BusinessTaskDetails = {
  status: BusinessTaskStatus;
  title: string;
  description: string;
  address: string;
  creationDate: Date;
  lastUpdatedOn: Date;
  doerPrice: number | null;
  materialPrice: number | null;
  companyProfit: number | null;
  company: BusinessTaskCompany;
  sender: BusinessTaskSender;
};

export const getBusinessTaskDetails = (
  id?: string,
): Promise<BusinessTaskDetails> =>
  businessApiClient.get(`internal/task/${id}`).then((resp) => resp.data);

export const useGetBusinessTaskDetails = (
  id?: string,
  options?: UseQueryOptions<BusinessTaskDetails>,
) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["business-task-id", id],
    queryFn: () => getBusinessTaskDetails(id),
    ...options,
  });
  return {
    data,
    isLoading,
    refetch,
  };
};
