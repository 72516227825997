import { GeneralResponseType } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useQuery, UseQueryOptions } from "react-query";

export type CancellationReason = {
  id: number;
  name: string;
  isTerminating: boolean;
};

type GetCancellationReasonsResp = GeneralResponseType<CancellationReason[]>;

export const getCancellationReasons = (): Promise<GetCancellationReasonsResp> =>
  apiClient.get("/catalog/cancellationReasons").then((resp) => resp.data);

export const useGetCancellationReasons = (
  options?: UseQueryOptions<GetCancellationReasonsResp>,
) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["catalog-cancellationReasons"],
    queryFn: () => getCancellationReasons(),
    ...options,
  });
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};
