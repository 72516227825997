import React, { useEffect, useState } from "react";
import "./RejectTaskModal.scss";
import { Modal, Radio } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { ValueOf } from "../../../../../utils/type-helpers";
import TagManager from "react-gtm-module";
import { useGetCancellationReasons } from "../../../../../services/retail/catalog";
import { TaskDetails } from "../../../../../services/retail/task/useGetTaskDetails";
import { useRejectTask } from "../../../../../services/retail/task/useRejectTask";

interface IRejectTaskModal {
  modalOpen: boolean;
  //eslint-disable-next-line
  setModalOpen: any;
  title: string;
  confirmationText: string;
  color?: string;
  icon?: JSX.Element;
  task?: TaskDetails;
}

const REJECT_TASK_STEPS = {
  CONFIRM: "confirm",
  REASONS: "reasons",
} as const;

type RejectTaskStep = ValueOf<typeof REJECT_TASK_STEPS>;

export const RejectTaskModal: React.FC<IRejectTaskModal> = ({
  modalOpen,
  setModalOpen,
  title,
  confirmationText,
  color,
  icon,
  task,
}) => {
  const navigate = useNavigate();

  const {
    data: cancellationReasons,
    isLoading,
    isRefetching,
  } = useGetCancellationReasons();

  const { mutate: mutateRejectTask, isLoading: rejectTaskLoading } =
    useRejectTask();

  const [mode, setMode] = useState<RejectTaskStep>(REJECT_TASK_STEPS.CONFIRM);
  const [selectedReason, setSelectedReason] = useState<number | null>(null);

  const handleCancellationReasonsStep = () => {
    setMode(REJECT_TASK_STEPS.REASONS);
  };

  const handleRejectTask = () => {
    if (task?.id && selectedReason) {
      mutateRejectTask(
        {
          taskId: task.id,
          cancel: true,
          reason: selectedReason,
        },
        {
          onSuccess: () => {
            const cancellationReason = cancellationReasons?.find(
              (reason) => reason.id.toString() === selectedReason?.toString(),
            );
            TagManager.dataLayer({
              dataLayer: {
                event: "reject_order",
                task_id: task?.id ?? "N/A",
                price: task?.price ?? "N/A",
                quantity: 1,
                cancellation_reason: cancellationReason?.name ?? "N/A",
              },
            });
            setModalOpen(false);
            navigate("/tasks");
          },
        },
      );
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
    setMode(REJECT_TASK_STEPS.CONFIRM);
  };

  return (
    <Modal
      open={modalOpen}
      className={"reject-task-modal"}
      title={title}
      onOk={
        mode === REJECT_TASK_STEPS.CONFIRM
          ? handleCancellationReasonsStep
          : handleRejectTask
      }
      okText={"დიახ"}
      cancelText={"არა"}
      onCancel={handleCancel}
      maskClosable
      destroyOnClose
      okButtonProps={{
        style: {
          backgroundColor: color || "#FF3100",
          borderColor: color || "#FF3100",
        },
        loading: isLoading || isRefetching || rejectTaskLoading,
        disabled:
          mode === REJECT_TASK_STEPS.REASONS &&
          selectedReason !== 0 &&
          !selectedReason,
        className:
          mode === REJECT_TASK_STEPS.REASONS
            ? "gtm-reject-task-confirm-button"
            : "",
      }}
      cancelButtonProps={{
        className:
          mode === REJECT_TASK_STEPS.REASONS
            ? "gtm-reject-task-cancel-button"
            : "",
      }}
      width={600}
    >
      {mode === "confirm" ? (
        <div className={"reject-task-modal-content-wrapper"}>
          <div
            className={"modal-icon-wrapper"}
            style={{ color: color || "#FF3100" }}
          >
            {icon || <InfoCircleOutlined />}
          </div>
          <div className={"modal-confirmation-text"}>{confirmationText}</div>
        </div>
      ) : (
        <Radio.Group
          onChange={(e) => setSelectedReason(e.target.value)}
          className={"reject-radio-group"}
        >
          {cancellationReasons?.map((item) => {
            return (
              <Radio key={item.id} value={item.id}>
                {item.name}
              </Radio>
            );
          })}
        </Radio.Group>
      )}
    </Modal>
  );
};
