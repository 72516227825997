import { GeneralResponseType, Status } from "../../generalTypes";
import { apiClient } from "../apiClient";
import { useQuery } from "react-query";
import { DoerDetails } from "../../shared-types";

type GetDoerDetailsResp = GeneralResponseType<DoerDetails>;

const getDoerDetails = (
  id?: number | string,
): Promise<GetDoerDetailsResp> | null => {
  if (id) {
    return apiClient.get(`/doer/${id}`).then((resp) => resp.data);
  }
  return null;
};

export type UseGetDoerDetailsResp = {
  isLoading: boolean;
  doer?: DoerDetails;
  status?: Status;
  refetch: any;
};

export const useGetDoerDetails = (
  id?: number | string,
): UseGetDoerDetailsResp => {
  const { data, isLoading, refetch } = useQuery(["doer-id", { id }], () =>
    getDoerDetails(id),
  );
  return {
    isLoading,
    doer: data?.data,
    status: data?.status,
    refetch,
  };
};
