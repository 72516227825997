import React, { useEffect } from "react";
import "./EditAboutMeModal.scss";
import { Col, Form, Modal, Row } from "antd";
import { formValidation } from "../../../../../utils/formValidation";
import TextArea from "antd/es/input/TextArea";

interface IEditAboutMeModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (values: any, afterSubmit?: () => void) => void;
  loading?: boolean;
  currentAboutMe: string;
}

export const EditAboutMeModal: React.FC<IEditAboutMeModal> = ({
  modalOpen,
  setModalOpen,
  onSubmit,
  loading,
  currentAboutMe,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!modalOpen) {
      form.resetFields();
    }
  }, [form, modalOpen]);

  const handleSubmit = (values: any) => {
    onSubmit?.(values, () => setModalOpen(false));
  };

  useEffect(() => {
    if (modalOpen) {
      form.setFieldsValue({
        aboutMe: currentAboutMe,
      });
    }
  }, [currentAboutMe, modalOpen]);

  return (
    <Modal
      open={modalOpen}
      className={"edit-about-me-modal"}
      title={"აღწერის რედაქტირება"}
      onOk={form.submit}
      okText={"დასტური"}
      cancelText={"დახურვა"}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: loading,
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col span={24}>
            <Form.Item name={"aboutMe"} rules={formValidation.required}>
              <TextArea
                className={"edit-doer-textarea"}
                autoSize={false}
                rows={5}
                placeholder={"შემსრულებლის შესახებ"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
