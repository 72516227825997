import React, { useState } from "react";
import "./Reviews.scss";
import { Pagination, Spin } from "antd";
import { reviewStatusTypes } from "../../../services/generalTypes";
import { CustomSelect } from "../../../components/CustomSelect/CustomSelect";
import { Review } from "./Review/Review";
import { useGetReviews } from "../../../services/retail/review";

export const Reviews = () => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 7,
  });
  const [selectedStatus, setSelectedStatus] = useState<number>(1);
  const { reviews, isLoading, refetch } = useGetReviews(
    pagination.currentPage,
    pagination.pageSize,
    selectedStatus,
  );

  const resetPagination = () => {
    setPagination({
      currentPage: 1,
      pageSize: 7,
    });
  };

  return (
    <div className={"reviews-container"}>
      <div className={"add-review-container"}>
        <CustomSelect
          value={selectedStatus}
          defaultValue={1}
          options={reviewStatusTypes}
          className={"review-status-select"}
          placeholder={"შეფასებეის სტატუსი"}
          onChange={(value: number) => {
            setSelectedStatus(value);
            resetPagination();
          }}
        />
        {/*<AddButton/>*/}
      </div>
      <div className={`reviews-list-wrapper ${isLoading ? "loading" : ""}`}>
        {isLoading ? (
          <Spin />
        ) : reviews ? (
          reviews.data.map((item) => {
            return (
              <Review key={item.id} review={item} refetchReviews={refetch} />
            );
          })
        ) : (
          <></>
        )}
      </div>
      <Pagination
        className={"reviews-list-pagination"}
        current={pagination.currentPage}
        defaultCurrent={1}
        total={reviews?.totalItemCount || 0}
        pageSize={pagination.pageSize}
        pageSizeOptions={[7, 15, 20, 50]}
        onChange={(page) => {
          setPagination((prev) => ({ ...prev, currentPage: page }));
        }}
        onShowSizeChange={(size) => {
          setPagination((prev) => ({ ...prev, pageSize: size }));
        }}
      />
    </div>
  );
};
