import { BusinessTaskStatus } from "../../../../services/generalTypes";
import { BusinessTaskData } from "../../../../services/business/task/useGetBusinessTasks";

export type DataSource = {
  id: string;
  key: string;
  title: string;
  description: string;
  address: string;
  companyId: string;
  doerPrice: number | null;
  materialPrice: number | null;
  companyProfit: number | null;
  status: BusinessTaskStatus;
  companyName: string;
  createdOn: Date;
};

export const getDataSource = (
  businessTasks: BusinessTaskData[],
): DataSource[] => {
  return businessTasks.map((task) => {
    return {
      id: task.id,
      key: task.id,
      title: task.title,
      description: task.description,
      address: task.address,
      companyId: task.companyId,
      doerPrice: task.doerPrice,
      materialPrice: task.materialPrice,
      companyProfit: task.companyProfit,
      status: task.status,
      companyName: task.companyName,
      createdOn: task.createdOn,
    };
  });
};
