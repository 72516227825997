import { businessApiClient } from "../businessApiClient";
import { useMutation } from "react-query";

type FinishBusinessTaskResp = {
  success: boolean;
};

export const finishBusinessTask = (
  taskId?: string,
): Promise<FinishBusinessTaskResp> =>
  businessApiClient.post(`/task/finish/${taskId}`).then((resp) => resp.data);

export const useFinishBusinessTask = (
  onSuccess?: (data: FinishBusinessTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(finishBusinessTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
