import React from "react";
import "./Doer.scss";
import { DoerContent } from "./DoerContent/DoerContent";
import { useParams } from "react-router";
import { Spin } from "antd";
import {
  useGetDoerDetails,
  UseGetDoerDetailsResp,
} from "../../../../services/retail/doer/useGetDoerDetails";
import { UseGetBusinessDoerDetailsResp } from "../../../../services/business/doer/useGetBusinessDoerDetails";

interface IDoerComponent {
  doerId?: string;
  getDoerDetails?: (
    id?: number | string,
  ) => UseGetDoerDetailsResp | UseGetBusinessDoerDetailsResp;
}

export const Doer: React.FC<IDoerComponent> = ({
  doerId,
  getDoerDetails = useGetDoerDetails,
}) => {
  const { id } = useParams();
  const { doer, isLoading, refetch } = getDoerDetails(doerId || id);

  return (
    <div className={"doer-container"}>
      <div className={`doer-page-wrapper ${isLoading ? "loading" : ""}`}>
        {isLoading ? (
          <Spin />
        ) : doer ? (
          <DoerContent doer={doer} refetch={refetch} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
