import React, { useEffect } from "react";
import "./EditBusinessTaskDetailsModal.scss";
import { Col, Form, FormInstance, Input, Modal, Row } from "antd";

type FieldValues = {
  address: string;
  doerPrice: number | null;
  materialPrice: number | null;
  companyProfit: number | null;
};

type EditBusinessTaskDetailsModalProps = {
  modalOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  initialValues: FieldValues;
  onSubmit: (values: any, afterSubmit?: () => void) => void;
  priceFieldsVisible: boolean;
};

export const EditBusinessTaskDetailsModal: React.FC<
  EditBusinessTaskDetailsModalProps
> = ({
  modalOpen,
  onClose,
  isLoading,
  initialValues,
  onSubmit,
  priceFieldsVisible,
}) => {
  const [form]: [FormInstance<FieldValues>] = Form.useForm();

  useEffect(() => {
    if (modalOpen) {
      form.setFieldsValue({ ...initialValues });
    }
  }, [form, modalOpen]);

  const onFinish = (values: FieldValues) => {
    onSubmit(values, onClose);
  };

  return (
    <Modal
      open={modalOpen}
      className={"edit-business-task-details-modal"}
      title={"დეტალების რედაქტირება"}
      onOk={form.submit}
      okText={"დასტური"}
      cancelText={"დახურვა"}
      onCancel={onClose}
      maskClosable
      destroyOnClose
      okButtonProps={{
        loading: isLoading,
      }}
      centered
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={"address"}
              label={"მისამართი"}
              labelCol={{ span: 24 }}
            >
              <Input placeholder={"მისამართი"} />
            </Form.Item>
          </Col>
        </Row>
        {priceFieldsVisible && (
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name={"doerPrice"}
                label={"შემსრულებლის ფასი(₾)"}
                labelCol={{ span: 24 }}
              >
                <Input placeholder={"შემსრულებლის ფასი"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"materialPrice"}
                label={"მასალების ფასი(₾)"}
                labelCol={{ span: 24 }}
              >
                <Input placeholder={"მასალების ფასი"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"companyProfit"}
                label={"კომპანიის სარგებელი(₾)"}
                labelCol={{ span: 24 }}
              >
                <Input placeholder={"კომპანიის სარგებელი"} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};
