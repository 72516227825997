import { businessApiClient } from "../businessApiClient";
import { useMutation } from "react-query";

type CheckEmailReq = {
  email: string;
};

export type CheckEmailData = {
  userId: string | null;
};

const checkEmail = (data: CheckEmailReq): Promise<CheckEmailData> =>
  businessApiClient.post("/auth/check-email", data).then((resp) => resp.data);

export const useCheckEmail = (
  onSuccess?: (data: CheckEmailData) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(checkEmail, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
