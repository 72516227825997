import React, { useState } from "react";
import { Task } from "../Task/Task";
import { Button, message } from "antd";
import { RejectTaskModal } from "../ActionModals/RejectTaskModal/RejectTaskModal";
import { ConfirmationModal } from "../ActionModals";
import { TaskDetails } from "../../../../services/retail/task/useGetTaskDetails";
import { useForceCompleteTask } from "../../../../services/retail/task/useForceCompleteTask";
import { useForceClientCompleteTask } from "../../../../services/retail/task/useForceClientCompleteTask";

interface IInProgressTaskWrapper {
  task: TaskDetails;
  refetchTask: any;
}

export const InProgressTaskWrapper: React.FC<IInProgressTaskWrapper> = ({
  task,
  refetchTask,
}) => {
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [forceCompleteModalOpen, setForceCompleteModalOpen] =
    useState<boolean>(false);
  const [completeTaskPushModalOpen, setCompleteTaskPushModalOpen] =
    useState<boolean>(false);

  const { mutate: mutateForceComplete, isLoading: forceCompleteLoading } =
    useForceCompleteTask();

  const {
    mutate: mutateForceClientCompleteTask,
    isLoading: forceClientCompleteTaskLoading,
  } = useForceClientCompleteTask();

  const handleForceCompleteTask = () => {
    mutateForceComplete(
      {
        taskId: task.id,
      },
      {
        onSuccess: () => {
          setForceCompleteModalOpen(false);
          refetchTask().catch((err: any) => message.error(err));
        },
        onError: (err) => {
          message.error(err);
        },
      },
    );
  };

  const handleForceClientCompleteTask = () => {
    mutateForceClientCompleteTask(
      {
        taskId: task.id,
        clientId: task.clientId,
      },
      {
        onSuccess: () => {
          setCompleteTaskPushModalOpen(false);
          refetchTask().catch((err: any) => message.error(err));
        },
        onError: (err) => {
          message.error(err);
        },
      },
    );
  };

  return (
    <Task
      task={task}
      actionButtons={
        <>
          <Button
            className={"action-button red"}
            onClick={() => setRejectModalOpen(true)}
          >
            {"დავალების გაუქმება"}
          </Button>
          <RejectTaskModal
            modalOpen={rejectModalOpen}
            setModalOpen={setRejectModalOpen}
            title={"დავალების გაუქმება"}
            confirmationText={"ნამდვილად გსურთ დავალების გაუქმება?"}
            task={task}
          />
          <Button
            className={"action-button red"}
            onClick={() => setForceCompleteModalOpen(true)}
          >
            {"ძალით დასრულება"}
          </Button>
          <ConfirmationModal
            modalOpen={forceCompleteModalOpen}
            setModalOpen={setForceCompleteModalOpen}
            title={"ძალით დასრულება"}
            confirmationText={
              "ნამდვილად გსურთ დავალების ძალით დასრულება პირდაპირი ტრანსფერით?"
            }
            onSubmit={handleForceCompleteTask}
            isLoading={forceCompleteLoading}
          />
          <Button
            className={"action-button blue"}
            onClick={() => setCompleteTaskPushModalOpen(true)}
          >
            {"შეტყობინების გაგზავნა"}
          </Button>
          <ConfirmationModal
            modalOpen={completeTaskPushModalOpen}
            setModalOpen={setCompleteTaskPushModalOpen}
            title={"შეტყობინების გაგზავნა"}
            confirmationText={
              "ნამდვილად გსურთ მომხმარებელს გაუგზავნოთ შეტყობინება, დაასრულოს დავალება?"
            }
            color={"#37a6f7"}
            onSubmit={handleForceClientCompleteTask}
            isLoading={forceClientCompleteTaskLoading}
          />
        </>
      }
    />
  );
};
