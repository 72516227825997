import { useState, useEffect, useMemo } from "react";
import { CompanyData, useGetCompanies } from "../services/business/company";
import { IOption } from "../services/generalTypes";

type UsePaginatedCompanies = {
  companyOptions?: IOption[];
  loadMoreCompanies: () => void;
  companiesLoading: boolean;
  hasMoreCompanies: boolean;
  pageNumber: number;
  resetPagination: () => void;
};

type UsePaginatedCompaniesParams = {
  searchText?: string;
};

export const usePaginatedCompanies = ({
  searchText,
}: UsePaginatedCompaniesParams): UsePaginatedCompanies => {
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: companies,
    isLoading: companiesLoading,
    totalItemCount: totalCompaniesCount,
  } = useGetCompanies({ pageNumber, searchText, pageSize: 10 });
  const [paginatedCompanies, setPaginatedCompanies] = useState<
    CompanyData[] | undefined
  >(companies);

  useEffect(() => {
    if (companies) {
      setPaginatedCompanies((prev) => {
        // return prev ? [...prev, ...companies] : companies;
        return pageNumber === 1 ? companies : [...(prev || []), ...companies];
      });
    }
  }, [companies, pageNumber]);

  const hasMoreCompanies =
    paginatedCompanies &&
    paginatedCompanies.length < (totalCompaniesCount || 0);

  const loadMoreCompanies = () => {
    if (hasMoreCompanies) setPageNumber((prev) => prev + 1);
  };

  const companyOptions = useMemo(() => {
    return paginatedCompanies?.map((company) => ({
      id: company.id,
      name: company.name,
    }));
  }, [paginatedCompanies]);

  const resetPagination = () => {
    setPageNumber(1);
  };

  return {
    companyOptions,
    loadMoreCompanies,
    companiesLoading,
    hasMoreCompanies: !!hasMoreCompanies,
    pageNumber,
    resetPagination,
  };
};
