import React, { useState } from "react";
import { Task } from "../Task/Task";
import { Button, message } from "antd";
import { RejectTaskModal } from "../ActionModals/RejectTaskModal/RejectTaskModal";
import { FindDoerModal } from "../ActionModals";
import { FormValues } from "../../../../components/FindDoerModalContent/FindDoerDetailsContent/FindDoerDetailsContent";
import { TaskDetails } from "../../../../services/retail/task/useGetTaskDetails";
import { useAssignTask } from "../../../../services/retail/task/useAssignTask";

interface ICreatedTaskWrapper {
  task: TaskDetails;
  refetchTask: any;
}

export const CreatedTaskWrapper: React.FC<ICreatedTaskWrapper> = ({
  task,
  refetchTask,
}) => {
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [findDoerModalOpen, setFindDoerModalOpen] = useState<boolean>(false);

  const { mutate: assignTask, isLoading: assignTaskLoading } = useAssignTask();

  const onTaskAssignSuccess = () => {
    setFindDoerModalOpen(false);
    refetchTask().catch((err: Error) => {
      message.error(err);
    });
  };

  const handleTaskAssign = (formValues: FormValues, doerId?: string) => {
    //1 day - 3600 * 24 unix
    //1 hour - 3600 unix
    //1 minute - 60 unix
    if (task.id && doerId) {
      const { price, days, hours, minutes, proposedStartTime, splitShare } =
        formValues;
      assignTask(
        {
          taskId: task.id,
          doerId,
          price: price,
          duration: days * 3600 * 24 + hours * 3600 + minutes * 60,
          proposedStartTime: proposedStartTime.unix(),
          splitShare: splitShare,
        },
        {
          onSuccess: onTaskAssignSuccess,
          onError: () => {
            message.error("დაფიქსირდა შეცდომა");
          },
        },
      );
    }
  };

  return (
    <Task
      task={task}
      actionButtons={
        <>
          <Button
            className={"action-button red"}
            onClick={() => setRejectModalOpen(true)}
          >
            {"დავალების უარყოფა"}
          </Button>
          <RejectTaskModal
            modalOpen={rejectModalOpen}
            setModalOpen={setRejectModalOpen}
            title={"დავალების უარყოფა"}
            confirmationText={"ნამდვილად გსურთ დავალების უარყოფა?"}
            task={task}
          />
          <Button
            className={"action-button blue"}
            onClick={() => setFindDoerModalOpen(true)}
          >
            {"შემსრულებლის მოძებნა"}
          </Button>
          <FindDoerModal
            modalOpen={findDoerModalOpen}
            setModalOpen={setFindDoerModalOpen}
            onAssignTask={handleTaskAssign}
            assignTaskLoading={assignTaskLoading}
          />
        </>
      }
    />
  );
};
