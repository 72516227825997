import { businessApiClient } from "../businessApiClient";
import { useQuery } from "react-query";
import { DoerDetails } from "../../shared-types";

export const getBusinessDoerDetails = (
  id?: number | string,
): Promise<DoerDetails> | null => {
  if (id) {
    return businessApiClient.get(`/doer/${id}`).then((resp) => resp.data);
  }
  return null;
};

export type UseGetBusinessDoerDetailsResp = {
  isLoading: boolean;
  doer?: DoerDetails | null;
  refetch: any;
};

export const useGetBusinessDoerDetails = (
  id?: number | string,
): UseGetBusinessDoerDetailsResp => {
  const { data, isLoading, refetch } = useQuery(
    ["business-doer-id", { id }],
    () => getBusinessDoerDetails(id),
  );
  return {
    isLoading,
    doer: data,
    refetch,
  };
};
