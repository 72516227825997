import { TaskId } from "../../shared-types";
import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

const acceptTaskOffer = (data: TaskId): Promise<string> =>
  apiClient.post("/task/acceptOffer", data).then((resp) => resp.data);

export const useAcceptTaskOffer = (
  onSuccess?: (data: string) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(acceptTaskOffer, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
