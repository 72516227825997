import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

type File = {
  fileId: string;
  extension: string;
  url: string;
  key: string;
  description: string;
};

type CreateTaskReq = {
  // adminId: string,
  Title: string;
  ClientId: string;
  Address: string;
  CityId: number;
  Description: string;
  WemplerTaskSenderType: number;
  InternalComment: string;
  Files: File[];
  // splitShare: number
};

const createTask = (data: CreateTaskReq): Promise<string> =>
  apiClient
    .post("/task/create", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp) => resp.data);

export const useCreateTask = (onSuccess?: () => void, onError?: () => void) => {
  const { isLoading, mutate } = useMutation(createTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
