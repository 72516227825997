import React, { useEffect } from "react";
import { Navigate } from "react-router";
import { useCometChatInit } from "../../hooks/useCometChatInit";
import { useCometChatLogin } from "../../hooks/useCometChatLogin";
import { getToken } from "../../utils/helpers";
import { useGetAdmin } from "../../services/retail/common/useGetAdmin";

interface IProtectedRoute {
  children: any;
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({ children }) => {
  const { admin } = useGetAdmin();

  const { cometInit, isComeChatInitialized } = useCometChatInit();
  const { loginCometChatUser } = useCometChatLogin();

  useEffect(() => {
    cometInit();
  }, []);

  useEffect(() => {
    if (isComeChatInitialized && admin?.communicatorId) {
      loginCometChatUser(admin?.communicatorId);
    }
  }, [isComeChatInitialized, admin]);

  return <>{getToken() ? children : <Navigate to={"/auth"} replace />}</>;
};
