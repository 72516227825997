import React from "react";
import { TableProps } from "antd";
import { DataSource } from "./datasource";
import {
  BUSINESS_TASK_STATUS_TYPES,
  BusinessTaskStatus,
  businessTaskStatusTypes,
} from "../../../../services/generalTypes";
import {
  getBusinessStatusColorById,
  getBusinessStatusNameById,
} from "../../../../utils/helpers";
import { StatusTag } from "../../../../components";
import moment from "moment";

export const getColumns = (
  selectedTab: BusinessTaskStatus,
): TableProps<DataSource>["columns"] => [
  {
    title: "სახელი",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "აღწერა",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "მისამართი",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "კომპანია",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "შემსრულებლის ფასი",
    dataIndex: "doerPrice",
    key: "doerPrice",
    hidden: selectedTab === BUSINESS_TASK_STATUS_TYPES.INCOMING,
    render: (_: any, { doerPrice }: DataSource) => {
      return <div>{doerPrice !== null ? `${doerPrice}₾` : "-"}</div>;
    },
  },
  {
    title: "მასალების ფასი",
    dataIndex: "materialPrice",
    key: "materialPrice",
    hidden: selectedTab === BUSINESS_TASK_STATUS_TYPES.INCOMING,
    render: (_: any, { materialPrice }: DataSource) => {
      return <div>{materialPrice !== null ? `${materialPrice}₾` : "-"}</div>;
    },
  },
  {
    title: "კომპანიის სარგებელი",
    dataIndex: "companyProfit",
    key: "companyProfit",
    hidden: selectedTab === BUSINESS_TASK_STATUS_TYPES.INCOMING,
    render: (_: any, { companyProfit }: DataSource) => {
      return <div>{companyProfit !== null ? `${companyProfit}₾` : "-"}</div>;
    },
  },
  {
    title: "ჯამური ფასი",
    dataIndex: "overallPrice",
    key: "overallPrice",
    hidden: selectedTab === BUSINESS_TASK_STATUS_TYPES.INCOMING,
    render: (
      _: any,
      { doerPrice, materialPrice, companyProfit }: DataSource,
    ) => {
      let overallPrice = "-";
      if (
        doerPrice !== null ||
        materialPrice !== null ||
        companyProfit !== null
      ) {
        overallPrice = `${(doerPrice || 0) + (materialPrice || 0) + (companyProfit || 0)}₾`;
      }
      return <div>{overallPrice}</div>;
    },
  },
  {
    title: "სტატუსი",
    dataIndex: "status",
    key: "status",
    hidden: selectedTab !== BUSINESS_TASK_STATUS_TYPES.COMPLETED,
    render: (_: any, { status }: DataSource) => {
      const statusId = businessTaskStatusTypes.find(
        (statusType) => statusType.name === status,
      )?.id;

      if (statusId) {
        return (
          <StatusTag
            color={getBusinessStatusColorById(statusId)}
            text={getBusinessStatusNameById(statusId)}
          />
        );
      }
    },
  },
  {
    title: "შექმნის თარიღი",
    dataIndex: "createdOn",
    key: "createdOn",
    render: (_: any, { createdOn }: DataSource) => {
      return <div>{moment(createdOn).format("DD/MM/YYYY, HH:mm")}</div>;
    },
  },
];
