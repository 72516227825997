import { useQuery } from "react-query";
import { GeneralResponseType } from "../../generalTypes";
import { apiClient } from "../apiClient";

type Admin = {
  communicatorId: string;
};

type GetAdminResp = GeneralResponseType<Admin>;

export const getAdmin = (): Promise<GetAdminResp> =>
  apiClient.get("/common/communicatorId").then((resp) => resp.data);

export const useGetAdmin = () => {
  const { data, isLoading } = useQuery(["common-communicatorId"], () =>
    getAdmin(),
  );
  return {
    isLoading,
    admin: data?.data,
    status: data?.status,
  };
};
